
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import TableUi from "@/components/Core/Table/TableUI.vue";

import { ServerType } from "prometheus-synced-ui";
import { LITE_FEATURE, RoughometerDetails, RoughometerItem } from "@/domain";

import { IRoughometerService } from "@/services";

@Component({
  components: {
    TableUi,
  },
})
export default class RoughometersTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get roughometerService() {
    return this.container.resolve<IRoughometerService>(S.ROUGHOMETER_SERVICE);
  }

  get existingSerialNumbers() {
    return _.map(this.roughometers, (p) => p.serialNumber);
  }

  get createComponent() {
    return () =>
      import("@/components/Core/Create/Equipments/RoughometerCreate.vue");
  }

  get updateComponent() {
    return () =>
      import("@/components/Core/Update/Equipments/RoughometerUpdate.vue");
  }

  get headers() {
    return [
      {
        text: "Numéro de série",
        align: "center",
        sortable: true,
        value: "serialNumber",
      },
      {
        text: "Type",
        align: "center",
        sortable: true,
        value: "equipmentType",
      },
      {
        text: "Marque",
        align: "center",
        sortable: true,
        value: "equipmentBrand.name",
      },
      {
        text: "Actions",
        align: "center",
        sortable: false,
        value: "actions",
        visibility: (serverType: ServerType) =>
          LITE_FEATURE.visibility(serverType),
      },
    ];
  }

  roughometers: RoughometerItem[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.roughometers = await this.roughometerService.getMany({
        ids: [],
        includeDisabled: true,
        includeEnabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  create(roughometer: RoughometerDetails) {
    this.roughometers.push(RoughometerItem.fromDetails(roughometer));
  }

  update(roughometer: RoughometerDetails) {
    const index = _.findIndex(this.roughometers, (r) => r.id == roughometer.id);
    if (index != -1) {
      this.roughometers.splice(
        index,
        1,
        RoughometerItem.fromDetails(roughometer)
      );
    }
  }

  async updateDisabled(id: string) {
    try {
      this.loading = true;
      const roughometer = _.find(this.roughometers, (r) => r.id == id)!;
      const result = await this.roughometerService.update(id, {
        serialNumber: roughometer.serialNumber,
        equipmentType: roughometer.equipmentType,
        equipmentBrandId: roughometer.equipmentBrand.id,
        disabled: !roughometer.disabled,
      });
      this.update(result);
    } finally {
      this.loading = false;
    }
  }
}
