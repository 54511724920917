
import { Component, Vue, Prop, InjectReactive } from "vue-property-decorator";

import { SERVER_CONFIG } from "@/config/literals";

import { LITE_FEATURE } from "@/domain";

import { ServerConfig } from "prometheus-synced-ui";

import { Feature } from "vue-feature-flags";
import UpdateDisabledButton from "@/components/General/UpdateDisabledButton.vue";

@Component({
  components: {
    UpdateDisabledButton,
    Feature,
  },
  data: () => {
    return {
      LITE_FEATURE,
    };
  },
})
export default class ActionsUI extends Vue {
  @InjectReactive(SERVER_CONFIG)
  serverConfig!: ServerConfig;

  @Prop({ required: true })
  id!: string;

  @Prop({ required: true })
  disabled!: boolean;

  @Prop({ required: true })
  loading!: boolean;

  @Prop({ required: true })
  allowDisabledChange!: boolean;

  @Prop({ required: true })
  isRemovable!: boolean;
}
